import React, {useState, useEffect} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {REGISTER_USER_URL, LOGIN_USER_MOBILE_OTP_URL} from '../../../configs';
import Axios from 'axios';
import { Link, useParams } from "react-router-dom";
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory } from "react-router-dom";

export default function SetLocation(params) {

  const { userMobile } = useParams();
    
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  // const [hasReferral, setHasReferral] = useState(false);
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [otpSent, setOtpSent] = useState(false);

  useEffect(()=>{
    setMobile(userMobile);
  },[userMobile])

  // const saveDefaultAddress = () => {

  //   let user = localStorage.getItem("user");
  //   let guest_address = localStorage.getItem("guest_address");
    
  //   user = JSON.parse(user);
  //   guest_address = JSON.parse(guest_address);

  //   let raw = {
  //     token : user.auth_token,
  //     user_id : user.id,
  //     latitude : guest_address?.latitude,
  //     longitude : guest_address?.longitude,
  //     address: guest_address?.address,
  //     house : guest_address?.flat,
  //     landmark: guest_address?.landmark,
  //     tag : guest_address?.tag
  //   }

  //   Axios.post(SAVE_ADDRESS_URL, raw)
  //   .then(res=>{
  //     console.log(res.data)
  //     // localStorage.setItem("address", address);

  //     //update default address
  //     user.default_address = res.data[0];
  //     user.default_address_id = res.data[0].id;
  //     localStorage.setItem("user", JSON.stringify(user));
      
  //     // setTimeout(()=>{
  //     //   history.push('/restaurants')
  //     // }, 800);

  //     // enqueueSnackbar('Account created.', {variant: 'success'});

  //     setLoading(false)

  //   })
  //   .catch(e=>{
  //     setLoading(false)
  //     console.log(e)
  //   });

  // }

  const signup=()=>{

    if(otpSent){
      if(otp !== ''){
        verifyOtp()
        return;
      }else{
        enqueueSnackbar('Please enter all details.', {variant: 'error'});
        return;
      }
    }

    if (name==='' || mobile.length<10 || email==='') {
      enqueueSnackbar('Please enter all details.', {variant: 'error'});
    }

    if (name.length < 3 || email.length < 5) {
      enqueueSnackbar('Please enter valid name and email. Must be more than 3 character', {variant: 'error'});
    }

    setLoading(true)

    let raw = {
      phone:mobile,
      name,
      email,
      referral: '',
      default_address_id: 0,
      token: null
    }

    Axios.post(REGISTER_USER_URL, raw)
    .then(res=>{
      console.log(res)

      if (res.data?.status === "CODE_SEND") {
        setOtpSent(true)
        // localStorage.setItem('user', JSON.stringify(res.data.data));

        // saveDefaultAddress()
        // verifyOtp()
        setLoading(false)

      }else{
        setLoading(false)
        enqueueSnackbar(res?.data?.message, {variant: 'error'});
      }
    })
    .catch(e=>{
      setLoading(false)
      enqueueSnackbar(e.response?.data?.message || 'Unable to create account', {variant: 'error'});
      console.log(e)
    });

  }

  const verifyOtp=()=>{
    let guest_address = JSON.parse(localStorage.getItem('guest_address'));

    setLoading(true);

    const raw = {
      phone: mobile, 
      otp,
      address: {
        address: guest_address.address,
        displayType: (guest_address.tag===0)?'HOME':(guest_address.tag===1)?'OFFICE':'OTHER',
        gpsAddressLine1: guest_address.address,
        // id: 0,
        isDefault: true,
        // is_operational: 0,
        latitude: guest_address.latitude,
        longitude: guest_address.longitude,
        // user_id: 0
      },
      meta: {
        // incrementalVersionCode: "V12.0.5.0.QFHINXM",
        type: "user",
        // MAC: "CA:7F:2E:91:9E:13",
        // manufacturer: "Xiaomi",
        // wifiIP: "0.0.0.0",
        // serial: "unknown",
        // fingerprint: "xiaomi/violet/violet:10/QKQ1.190915.002/V12.0.5.0.QFHINXM:user/release-keys",
        // releaseVersionCode: "10",
        // host: "c4-miui-ota-bd187.bj",
        // model: "Redmi Note 7 Pro",
        // baseVersionCode: "1",
        // location: "",
        // id: "QKQ1.190915.002",
        // sdk: "29",
        // UUID: "417b9c903acbaa7a",
        // brand: "xiaomi",
        // user: "builder",
        // board: "violet"
      },
    }

    Axios.post(LOGIN_USER_MOBILE_OTP_URL, raw)
    .then(res=>{
      
      console.log(res.data)

      enqueueSnackbar('Account created.', {variant: 'success'});

      if (res.data?.data) {
        // enqueueSnackbar('Logged In successfully!', {variant: 'success'});
        localStorage.setItem('user', JSON.stringify(res.data.data));

        if(localStorage.getItem('guest_address')!==null){

          setTimeout(()=>{
            history.push('/restaurants')
          }, 800);
          // let guest_address = JSON.parse(localStorage.getItem('guest_address'));
          
          // let raw = {
          //   token : res.data.data.auth_token,
          //   user_id : res.data.data.id,
          //   latitude : guest_address.latitude,
          //   longitude : guest_address.longitude,
          //   address: guest_address.address,
          //   house : guest_address.flat,
          //   landmark: guest_address.landmark,
          //   tag : (guest_address.tag===0)?'HOME':(guest_address.tag===1)?'OFFICE':'OTHER',
          //   get_only_default_address: null
          // }
          // saveLocation(raw)

          // let user = res.data.data;
          // user.default_address = guest_address;
          // localStorage.setItem('user', JSON.stringify(res.data.data));
        }
        else{
          setTimeout(()=>{
            if(localStorage.getItem('fromCart')!==null){
              history.push('/cart')
            }else{
              history.push('/restaurants')
            }
          }, 800);
          setLoading(false);
        }
      }else{
        enqueueSnackbar(res.data.message, {variant: 'error'});
        setLoading(false);
      }
    })
    .catch((e)=>{
      console.log(e)
      // enqueueSnackbar(res.data.message, {variant: 'error'});
      // setLoading(false);
    })
  }

  if(localStorage.getItem('user') !== null ){
    return(<Redirect to="/account" />)
  }

  return (
        <div className="container">
            <div className="row">

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 30, paddingTop: 10}}>
                    <Typography style={{position: 'absolute', right: 10}} variant="body1" gutterBottom>
                      <Link to="/restaurants">Skip</Link>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 10}}>
                    <Typography variant="caption" gutterBottom>
                      Welcome to
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                      <b>PURE EATS</b>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 p-0">

                  <div style={{padding: 25}}>

                    <div style={{paddingBottom: 10}}>
                      <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                        <b>CREATE ACCOUNT</b>
                      </Typography>
                    </div>

                    <div className="row" style={{marginTop: 40, marginBottom: '1rem'}}>
                      <div className="col-sm-12">
                        <TextField disabled={true} value={mobile} onChange={(e)=>setMobile(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Mobile No." />
                        <TextField disabled={otpSent} onChange={(e)=>setName(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Name" />
                        <TextField disabled={otpSent} onChange={(e)=>setEmail(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Email" />
                        
                        {otpSent && (
                          <TextField onChange={(e)=>setOtp(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="OTP" />
                        )}


                        {/* {hasReferral && (
                          <TextField onChange={(e)=>setReferral(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="Referral code" />
                        )}
                        
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasReferral} onChange={(e)=>setHasReferral(e.target.checked)} name="checkedA" />}
                            label="I have referral code"
                          />
                        </FormGroup> */}

                      </div>
                      <div className="col-sm-12">
                        <div style={{textAlign: 'center', width: '100%', marginBottom: 5}}>
                          <Button disabled={loading} onClick={()=>signup()} style={{marginBottom: 20, marginTop: 40, fontSize: 11, height: 45, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                            {loading ? 'Please Wait...' : 'CREATE ACCOUNT'}
                          </Button>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div style={{Padding: 20}}>
                          <Typography className="mt-3" style={{fontSize: '0.89rem', textAlign: 'center'}} variant="h6" gutterBottom>
                            Arady have an account ? <Link style={{borderBottom: '2px solid black'}} to='/login'>Login</Link>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}
