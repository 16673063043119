import React, {useEffect, useState} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import { useHistory } from "react-router-dom";
import LiveGoogleMap from "../../../components/GeoLocation/Map/LiveGoogleMap";
// import {GET_ADDRESS_FROM_COORDINATES} from '../../../configs';
import Axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import {GET_ORDER_DETAIL_URL, UPDATE_USER_INFO_URL} from '../../../configs';
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CallIcon from '@material-ui/icons/Call';

export default function LiveTrack(params) {
  // const classes = useStyles();
  const history = useHistory();

  // const location = useLocation();

  const {order_id} = useParams();

  // const [google_script_loaded, setgoogle_script_loaded] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [liveTrack, setLiveTrack] = useState(true);
  const [order, setOrder] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [runningOrder, setRunningOrder] = useState({});


  // const saveLocation=()=>{
  //   localStorage.setItem("address", address);
  //   history.push('/restaurants');
  // }
  
  useEffect(()=>{
    
    updateOrder();
    localStorage.setItem('liveTrack', 'YES');

    return () => {
      // console.log("unmounting");
      setOrder(undefined);
      // setLiveTrack(false);
      // localStorage.setItem('liveTrack', 'NO');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[order_id])

  useEffect(()=>{
    updateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[order])

  // useEffect(() => {
  //   return () => {
  //     setOrder(undefined);
  //   };
  // }, []);

  // useEffect(()=>{
  //   updateOrder()
  //   updateUser()
  // },[]);

  const updateOrder=()=>{
    let user = JSON.parse(localStorage.getItem("user"))

    Axios.post(GET_ORDER_DETAIL_URL, {
        token: user.auth_token,
        user_id: user.id,
        order_id: order_id,
    })
    .then(res => {
        setOrder(res.data.data)
        setRestaurant(res.data.data.restaurant)
        setLoading(false)
    })
    .catch(function(error) {
        console.log(error);
    });

    // const check = localStorage.getItem('liveTrack');
    
    if(order!==undefined){
      // console.log(location.pathname);
      setTimeout(()=>{
        if(user) {
          updateOrder()
        }
      },10000)
    }
  }

  const updateUser=()=>{
    let user = JSON.parse(localStorage.getItem("user"))

    if(!user) return true;

    Axios.post(UPDATE_USER_INFO_URL, {token:user.auth_token, user_id: user.id, unique_order_id: order.unique_order_id})
    .then(res=>{
      // setUser(res.data.data);
      // console.log(res.data.data);
      user.wallet_balance=res.data.data.wallet_balance;
      localStorage.setItem('user', JSON.stringify(user))

      if(res.data.data?.running_orders){
        setRunningOrder(res.data.data.running_orders.filter(i => i.unique_order_id === order.unique_order_id)[0]);
      }
    })
    .catch(e=>console.log(e));
  }
  
  if(loading){
    return(
      <span className="p-3">Loading...</span>
    )
  }

  return (
        <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <IconButton onClick={()=>history.goBack()} aria-label="back">
                  <KeyboardBackspaceIcon style={{marginRight: 5}} />
                </IconButton>
              </div>
              <div className="col-10">
                <span style={{fontWeight: 'bold'}}>ORDER # {order.unique_order_id}</span>
                <p className="m-0" style={{fontWeight: 'bold',color: '#9a9a9a'}}>{restaurant.name}</p>
                <p className="m-0" style={{fontWeight: 'bold',color: '#9a9a9a'}}>{new Date(order.created_at).toLocaleString('en-IN', { hour12: true })} | {order.orderitems.length} items, Rs {order.total}</p>
              </div>
            </div>

            {(order.delivery_type && order.delivery_type !== "2") && (
            <div className="row">
              <div className="col-12 p-0">
                <div style={{height: "55vh"}}>
                  <LiveGoogleMap order={order} restaurant={restaurant}  />
                </div>
              </div>
            </div>
            )}

            <div className="row">

                <div className="col-sm-12 col-md-6 p-0">

                  {(order.orderstatus_id === 5) && (
                    <div>
                      <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #fff'}}>
                          <div className="col-2 text-center">
                            <img alt='imagehere' src="/assets/img/android/cooker.png" className="img-fluid pb-2" style={{width: 24}} />
                            
                            <br />
                            
                            <span style={{padding: '0px 3px',border: '1px solid rgba(115, 115, 255, 0.87)',fontSize: 16,background: '#0ab30a',color: 'white',borderRadius: 4,width: '100%',textAlign: 'center',height: 23}}>
                              Done
                            </span>
                          </div>

                          <div className="col-7">
                            <Typography variant="body1"><b>Order Successfully Delivered.</b></Typography>
                            <br />
                            <br />
                            
                            <Typography variant="caption">Rating: 4.98</Typography>
                            
                            <br />
                            
                            <Typography variant="caption">Vehicle: {runningOrder?.delivery_details?.vehicle_number}</Typography>
                            
                            <br />
                            <br />
                            <br />
                            
                            {/* <Typography style={{padding: 10,border: '1px solid #bbbbbb',textAlign: 'center'}} variant="body1">Delivery Pin: <span style={{color: 'orange'}}><b>{order.delivery_pin}</b></span></Typography> */}
                          </div>

                          <div className="col-3 text-center">
                            <IconButton style={{position: 'absolute', right: 10, background: 'orange',color: 'white'}} className="float-right" size="small" onClick={()=>{window.location = (`tel:${runningOrder?.delivery_details?.phone}`)}} aria-label="call">
                              <CallIcon className="ml-1" />
                            </IconButton>
                            <img alt='imagehere' src={"https://admin.pureeats.in/assets/img/delivery/"+runningOrder?.delivery_details?.photo} className="img-fluid pb-2"/>
                          </div>
                      </div>
                    </div>
                  )}

                  {((order.orderstatus_id===11 || order.orderstatus_id===710 || order.orderstatus_id===10 || order.orderstatus_id===3 || order.orderstatus_id===4  || order.orderstatus_id===73)) && (
                  <div>
                    <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #fff'}}>
                        <div className="col-2 text-center">
                          <img alt='imagehere' src="/assets/img/android/cooker.png" className="img-fluid pb-2" style={{width: 24}} />
                          <br />
                          <span style={{padding: '0px 3px',border: '1px solid rgba(115, 115, 255, 0.87)',fontSize: 16,background: '#0ab30a',color: 'white',borderRadius: 4,width: '100%',textAlign: 'center',height: 23}}>Live</span>
                        </div>

                        <div className="col-7">
                          <Typography variant="body1"><b>
                            {(order.orderstatus_id===3)?"Food is being prepared":
                            (order.orderstatus_id===73)?"Ready to pick up":
                            (order.orderstatus_id===710)?"Delivery boy reached at restaurant":
                            (order.orderstatus_id===11)?"Delivery boy reached":
                            "Order Picked"}</b>
                          </Typography>
                          
                          {(order.orderstatus_id===3) && (<Typography variant="caption">{runningOrder?.delivery_details?.name} has been assigned to deliver your order</Typography>)}
                          {(order.orderstatus_id===73) && (<Typography variant="caption">{runningOrder?.delivery_details?.name} is on the way to pickup your order</Typography>)}
                          {(order.orderstatus_id===4) && (<Typography variant="caption">{runningOrder?.delivery_details?.name} has picked up your order and is on the way to deliver.</Typography>)}
                          {(order.orderstatus_id===11) && (<Typography variant="caption">{runningOrder?.delivery_details?.name} has reached your location.</Typography>)}
                          {(order.orderstatus_id===710) && (<Typography variant="caption">{runningOrder?.delivery_details?.name} has reached at the restaurant to pick up your order.</Typography>)}
                          
                          <br />
                          <br />
                          
                          <Typography variant="caption">Rating: 4.98</Typography>
                          
                          <br />
                          
                          <Typography variant="caption">Vehicle: {runningOrder?.delivery_details?.vehicle_number}</Typography>
                          
                          <br />
                          <br />
                          <br />
                          
                          {(order.orderstatus_id===4 || order.orderstatus_id===11) && (<Typography style={{padding: 10,border: '1px solid #bbbbbb',textAlign: 'center'}} variant="body1">Delivery Pin: <span style={{color: 'orange'}}><b>{order.delivery_pin}</b></span></Typography>)}
                        </div>

                        <div className="col-3 text-center">
                          <IconButton style={{position: 'absolute', right: 10, background: 'orange',color: 'white'}} className="float-right" size="small" onClick={()=>{window.location = (`tel:${runningOrder?.delivery_details?.phone}`)}} aria-label="call">
                            <CallIcon className="ml-1" />
                          </IconButton>
                          <img alt='imagehere' src={"https://admin.pureeats.in/assets/img/delivery/"+runningOrder?.delivery_details?.photo} className="img-fluid pb-2"/>
                        </div>
                    </div>
                  </div>
                  )}

                  {(order.orderstatus_id!==11 && order.orderstatus_id!==710 && order.orderstatus_id!==73 && order.orderstatus_id!==3 && order.orderstatus_id!==4 && order.orderstatus_id!==5 && order.orderstatus_id!==10) && ( 
                    <div>
                      <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #c2c2c2'}}>
                          <div className="col-3 text-center">
                            <img alt='imagehere' src="/assets/img/android/slip.png" className="img-fluid pb-2" style={{width: 24}} />
                            <br />
                            <span style={{padding: '0px 3px',border: '1px solid #7373ffde'}}>{(order.orderstatus_id===1)?"LIVE":"DONE"}</span>
                          </div>
                          <div className="col-6 p-0">
                            <Typography variant="body1" className="mt-5 pt-5"><b>{(order.orderstatus_id===1)?"Waiting for restaurant to accept order":"Order Received"}</b></Typography>
                            <Typography variant="body2"></Typography>
                          </div>
                          <div className="col-3 p-0">
                            <Button variant="outlined" style={{fontSize: 8,position: 'absolute', top: 0,right: 10}} className="float-right" size="small" onClick={()=>{window.location = (`tel:${restaurant.contact_number}`)}} aria-label="call">
                              Call Now
                              <CallIcon className="ml-1" />
                            </Button>
                          </div>
                      </div>
                      <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #c2c2c2'}}>
                          <div className="col-3 text-center">
                            <img alt='imagehere' src="/assets/img/android/cooker.png" className="img-fluid pb-2" style={{width: 24}} />
                            <br />
                            <span style={{padding: '0px 3px',border: '1px solid #7373ffde'}}>{(order.orderstatus_id===1)?"Next":(order.orderstatus_id===2 || order.orderstatus_id===10  || order.orderstatus_id===7)?"Live":"Done"}</span>
                          </div>
                          <div className="col-9">
                            <Typography variant="body1"><b>{(order.orderstatus_id===1)?"Waiting":(order.orderstatus_id===2)?"Food is being prepared":(order.orderstatus_id===10 || order.orderstatus_id===7)?"Food is ready to pickup":"Done"}</b></Typography>
                            {order.orderstatus_id===2 && (<Typography variant="caption">Restaurant confirmed our order. We'll assign a Delivery Executive to your order shortly.</Typography>)}
                            {order.orderstatus_id===4 && (<Typography variant="caption">Restaurant handed over the food to Delivery Executive.</Typography>)}
                          </div>
                      </div>
                      {order.delivery_type !== "2" && (
                      <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #c2c2c2'}}>
                        <div className="col-3 text-center">
                          <img alt='imagehere' src="/assets/img/android/scooter.png" className="img-fluid pb-2" style={{width: 24}} />
                          <br />
                          <span style={{padding: '0px 3px',border: '1px solid #7373ffde'}}>{(order.orderstatus_id===1)?"...":(order.orderstatus_id===2 || order.orderstatus_id===10)?"NEXT":"LIVE"}</span>
                        </div>
                        <div className="col-9">
                          <Typography variant="body1" className="mt-5 pt-5"><b>{((order.orderstatus_id!==6 || order.orderstatus_id!==10) && (order.orderstatus_id!==4))?"Waiting":(order.orderstatus_id===4)?"Order Picked Up":"Done"}</b></Typography>
                          <Typography variant="body2"></Typography>
                        </div>
                      </div>
                      )}

                      {order.delivery_type === "2" && (
                      <div className="row" style={{color: '#636363', fontWeight: 'bold', fontSize: 11, padding: 10, borderBottom: '1px solid #c2c2c2'}}>
                        <div className="col-3 text-center">
                          <img alt='imagehere' src="/assets/img/self-pickup.webp" className="img-fluid pb-2" style={{width: 43}} />
                          <br />
                          <span style={{padding: '0px 3px',border: '1px solid #7373ffde'}}>{(order.orderstatus_id===1)?"...":(order.orderstatus_id===2 || order.orderstatus_id===10)?"NEXT":"LIVE"}</span>
                        </div>
                        <div className="col-9">
                          <Typography variant="body1" className="mt-5 pt-5"><b>{((order.orderstatus_id!==6 || order.orderstatus_id!==10) && (order.orderstatus_id!==4))?"Self Pickup":(order.orderstatus_id===4)?"Order Picked Up":"Done"}</b></Typography>
                          {(order.orderstatus_id===7 || order.orderstatus_id===11 || order.orderstatus_id===7) && (
                            <>
                            <Typography style={{padding: 10,border: '1px solid #bbbbbb',textAlign: 'center'}} variant="body1">Delivery Pin: <span style={{color: 'orange'}}><b>{order.delivery_pin}</b></span></Typography>
                            <Typography variant="body1" className='text-center' >Provide this pin to restaurant to collect your order.</Typography>
                            </>
                          )}
                        </div>
                      </div>
                      )}

                    </div>
                  )}

                </div>
                
            </div>


        </div>
    
  );
}

