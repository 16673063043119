import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {PLACE_ORDER_URL, GET_SETTINGS_URL, RAZORPAY_CAPTURE_CHECK} from '../../../configs';
import { useHistory, Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSnackbar } from 'notistack';

export default function Checkout(params){
  
  // const { resSlug } = useParams();
  const history = useHistory();
  
  const { enqueueSnackbar } = useSnackbar();

  const [restaurant, setRestaurant] = useState({})
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [partial_wallet, setpartial_wallet] = useState(false);
  const [settings, setSettings] = useState({});

  // useEffect(()=>{
  //     Axios.post(GET_RESTAURANT_INFO_URL+'/'+resSlug)
  //     .then(res=>{
  //         console.log(res.data)
  //         setRestaurant(res.data)
  //     })
  //     .catch((e)=>console.log(e))
  // }, [resSlug]);

  // useEffect(()=>{
  //     Axios.post(GET_RESTAURANT_ITEMS_URL+'/'+resSlug)
  //     .then(res=>{
  //         // console.log(res.data)
  //         setItems(res.data.items)
  //         setRecommended(res.data.recommended)
  //         setArrayHolderItems(res.data.items)
  //         setArrayHolderRecommended(res.data.recommended)
  //         setLoading(false);
  //     })
  //     .catch((e)=>console.log(e))
  // }, [resSlug]);
  
  useEffect(()=>{
    let user = JSON.parse(localStorage.getItem('user'));
    let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
    let cartItems = JSON.parse(localStorage.getItem('cart'));

    Axios.post(GET_SETTINGS_URL)
    .then(res => {
      setSettings(res.data)
    })
    .catch(error => {
      console.log(error);
    })

    setUser(user);
    setRestaurant(cartRestaurant);
    setCart(cartItems);
  }, [])

  const payOnline=async(e)=>{
    let total = parseInt(localStorage.getItem('totalPayable'));
    let totalPayable = (partial_wallet?(total - user.wallet_balance) < 0 ? 0:total - user.wallet_balance:total);
    if(totalPayable === 0){
      return;
    }

    let options = {
      "key": settings.find(i => i.key === 'razorpayKeyId').value,
      "amount": Math.round(totalPayable)*100, // 2000 paise = INR 20, amount in paisa
      "name": "PureEats",
      "description": "Pay for your order.",
      "image": "/assets/img/logos/default.png",
      "handler": function (response){
        // alert(response.razorpay_payment_id);
        placeOrder('RAZORPAY', response.razorpay_payment_id)
      },
      "prefill": {
        "name": JSON.parse(localStorage.getItem('user')).name,
        "email": JSON.parse(localStorage.getItem('user')).email
      },
      "notes": {
        "address": localStorage.getItem('address')
      },
      "theme": {
        "color": "#f90e30"
      }
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  }

  const resetCart = () => {
    localStorage.removeItem("orderComment");
    localStorage.removeItem("cart");
    localStorage.removeItem("cartRestaurant");
    localStorage.removeItem("applyedCoupon");
    localStorage.removeItem("totalPayable");
    localStorage.removeItem("tip");

    history.push('myOrders');
  }

  const placeOrder = (method, payment_id='') => {
    setLoading(true);
    // let userSetAddress = localStorage.getItem("userSetAddress");
     
     let order_method = method;
     let total = parseInt(localStorage.getItem('totalPayable'));
     let tip = parseInt(localStorage.getItem('tip'));
     let totalPayable = (partial_wallet?(total - user.wallet_balance)<0?0:total - user.wallet_balance:total);

     if(totalPayable === 0 || method==='WALLET'){
      order_method = 'WALLET';
      totalPayable = total;
     }
    
      let raw = {
          TAG: "PlaceOrderRequest",
          token: user.auth_token,
          user_id: user.id,
          restaurant_id: restaurant.id,
          delivery_distance: restaurant.delivery_distance,
          user: {delivery_details: null, running_order: null, success: true, data: user},
          order_items: cart,
          coupon: ((localStorage.getItem("applyedCoupon") !== null)?JSON.parse(localStorage.getItem("applyedCoupon")).code:''),
          // location: {, lat/:, lng: , house: '', tag: null},
          full_address: user?.default_address?.address || localStorage.getItem("address"),
          location: {
            latitude: user.default_address?.latitude || localStorage.getItem("userLat"),
            longitude: user.default_address?.longitude || localStorage.getItem("userLng")
          },
          order_comment: localStorage.getItem("orderComment"),
          total: total,
          payable: method==='WALLET'?total:totalPayable,
          tip_amount: tip,
          method: order_method,
          payment_token: '',
          payment_id: payment_id,
          delivery_type: localStorage.getItem('delivery_type') === 'self'?2:1,
          partial_wallet_amount: method==='WALLET'?true:partial_wallet,
          payment_mode: method,
          delivery_charge: Math.round(restaurant.delivery_charge)
      };

      console.log(raw);
      // return;

      Axios.post(PLACE_ORDER_URL, raw)
      .then(response => {
          const checkout = response.data;

          if (checkout.success) {
             
             //capture api for razorpay
              if(order_method === 'RAZORPAY'){
                const raw = {
                  order_id: checkout.data.id,
                  payment_method: "RAZORPAY",
                  razorpayPaymentID: payment_id,
                  razorPayOrderId: checkout.data.rzp_order_id,
                  transactionId: "",    
                  transactionRefId: checkout.data.unique_order_id,
                  transactionStatus: "SUCCESS",
                }
                Axios.post(RAZORPAY_CAPTURE_CHECK, raw)
                .then(res => {
                  if(res.data?.success){
                    resetCart();
                  }else{
                    enqueueSnackbar('Unable process your payment!', {variant: 'error'});
                  }
                })
                .catch(error => {
                  console.log(error);
                })
              }else{
                resetCart();
              }
          }else{
            enqueueSnackbar('Unable to place order!', {variant: 'error'});
          }

          setLoading(false);
      })
      .catch(function(error) {
          enqueueSnackbar('Unable to place order!', {variant: 'error'});
          setLoading(false);
          console.log(error);
      });
  };

  const makeUseWallet=()=>{
    if(user.wallet_balance > 0){
      setpartial_wallet(!partial_wallet)
      // if(parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance < 0){
      //   placeOrder('WALLET');
      // }
    }
  }

  const minusWalletRemaning=()=>{
    let t = parseInt(localStorage.getItem('totalPayable'))
    let r = t - user.wallet_balance;
    return r>0?"0":Math.round(Math.abs(r))
  }

  if(cart===null){
    return(<Redirect to="/restaurants" />);
  }


  return (
    <div className="container-fluid">

        <div className="row mb-4">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>

            <Typography style={{display: 'inline'}} variant="h6">
              <b>PAYMENTS</b>
            </Typography><br />
            <Typography style={{display: 'inline', 'marginLeft': '13%',position: 'absolute',top: 29}} className="text-muted" variant="body2">
              {cart.length} items, To pay: ₹ {localStorage.getItem('totalPayable')}, Delivery Type: {localStorage.getItem('delivery_type') === 'self'?'Self Pickup':'Home Delivery'}
            </Typography>
          </div>
        </div>

        <CardActionArea onClick={()=>makeUseWallet()}>
        <div className="row">
          <div className="col-3 p-0 text-center">
            <img alt="..." src="/assets/img/various/wallet.png" className="img-fluid" />
          </div>
          <div className="col-6 p-0">
            <CardContent className="p-1">
              <Typography gutterBottom variant="body1" component="h2">
                <b>WALLET </b> ₹ {partial_wallet?minusWalletRemaning():user.wallet_balance}
              </Typography>
            </CardContent>
          </div>
          {partial_wallet && (
          <div className="col-3 p-0 text-center">
            <img style={{width:58}} alt="..." src="/assets/img/check.png" className="img-fluid" />
          </div>
          )}
        </div>
        </CardActionArea>

        <div className="row" style={{ marginTop: 20, marginBottom: 10}}>
        {(partial_wallet && minusWalletRemaning()==="0") && (
        <div className="col-9">
          <Typography variant="caption"><b>COD not available when using wallet</b></Typography>

          <Button disabled={loading} onClick={()=>payOnline()} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
            {(loading)?'Please Wait...':'PAY ONLINE REMANING ₹ '+(partial_wallet?((parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance)<0?"0":parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance):localStorage.getItem('totalPayable'))}
          </Button>
        </div>
        )}

        {(partial_wallet && minusWalletRemaning()>0) && (
        <div className="col-9">
          <Typography variant="caption"><b>COD not available when using wallet</b></Typography>

          <Button disabled={loading} onClick={()=>placeOrder('WALLET')} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
            {(loading)?'Please Wait...':'PLACE ORDER' }
          </Button>
        </div>
        )}
        </div>

        <div className="row" style={{borderBottom: '1px solid grey', marginTop: 20, marginBottom: 10}}>
            <div className="col-12 p-3">
              <Typography variant="caption"><b>WALLETS</b></Typography>
            </div>
            <div className="col-3 text-center p-3">
              <img onClick={()=>payOnline()} alt='imagehere' src="/assets/img/android/razorpay.png" className="img-fluid" style={{width: 54}} />
            </div>
            <div className="col-9 p-3">
              <Typography onClick={()=>payOnline()} variant="body1"><b>Razorpay</b></Typography>
            </div>
        </div>

        <div className="row">
            <div className="col-12 p-3">
              <Typography variant="caption"><b>PAY ON DELIVERY</b></Typography>
            </div>
        </div>

        <div className="row" style={{marginBottom: 75}}>
            <div className="col-3 text-center">
              <img alt='imagehere' src="/assets/img/android/rupees.png" className="img-fluid" style={{width: 54}} />
            </div>


            {!partial_wallet && (
            <div className="col-9">
              <Typography variant="caption"><b>Cash</b></Typography>
              
              {(localStorage.getItem('delivery_type') === 'self' || parseInt(localStorage.getItem('subTotal')) > 400)  && (
                <>
                  <Typography variant="body2">Cash on Delivery is not available</Typography>
                  <Typography variant="body2">Please pay using online payment method</Typography>
                </>
              )}

              

              {/* {!restaurant.is_accept_cod && ( */}
                <>
                  {(localStorage.getItem('delivery_type') !== 'self' && parseInt(localStorage.getItem('subTotal')) < 400)  && (
                    <Button disabled={loading} onClick={()=>placeOrder('COD')} variant="contained" color="primary" style={{width: '100%', height: 40, color: 'white', marginTop: 10}}>
                      {(loading)?'Please Wait...':'PAY WITH CASH ₹ '+(partial_wallet?((parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance)<0?"0":parseInt(localStorage.getItem('totalPayable')) - user.wallet_balance):localStorage.getItem('totalPayable'))}
                    </Button>
                  )}

                  {/* {(restaurant.is_accept_cod && localStorage.getItem('delivery_type') !== 'self' && parseInt(localStorage.getItem('subTotal')) > 400)  && (
                    <Button disabled={true} variant="contained" color="primary" style={{width: '100%', height: 40, color: '#555555', marginTop: 10}}>
                      COD not allowed on order amount above ₹ 400
                    </Button>
                  )} */}

                </>
              {/* )} */}

              {!restaurant.is_accept_cod && (
                <Button disabled={true} variant="contained" color="primary" style={{width: '100%', height: 40, color: '#555555', marginTop: 10}}>
                  This restaurant is currently not accepting COD
                </Button>
              )}

            </div>
            )}
        </div>

    </div>
  );
}

