import React from "react";

import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Button from '@material-ui/core/Button';

export default function Faq(params){

  const history = useHistory();

  return (
    <div className="container-fluid">

        <div className="row" style={{position: 'fixed', top: 0, zIndex: 9999, background: 'white', width: 'inherit'}}>
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2 mb-2" color="text-secondary" variant="h6">
            <b>FAQs</b>
            </Typography>
          </div>
        </div>

        
        <div className="row" style={{backgroundColor: '#dedede', marginTop: 70}}>
          <div className="col-12">
          <br />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-3 p-1 bg-white">
              <div style={{padding: 5, textAlign: 'justify'}}>
                  <div
                    Style={{padding: 5}}
                    role="tabpanel"
                    id="vertical-tabpanel-1"
                    aria-labelledby="vertical-tabpanel-1"
                  >

                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What is Pure Eats?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Pure Eats is a food home delivery service with the range of 500 restaurants<br />

                         Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                         Bally Howrah , Pin: -711227.<br />
                         State: -West Bengal,<br />
                         Email: - help@pureeats.in<br />
                         Customer support number - 18001238508<br />

                         We are one of INDIA's premiers Home Delivery Service. Operational since 2020, we have served over 100
                         people so far.<br />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Where is Pure Eats registered and where is its Head office?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We are Registered at or our Head Office is currently at;<br />
                         PURE EATS INDIA,<br />
                         
                         Flat no 307 SAPUIPARA, near Mota shiv Mandir ( Rakhi Factory)<br />
                         Bally Howrah , Pin: -711227.<br />
                         State: -West Bengal,<br />
                         Email: - help@pureeats.in<br />
                         Customer support number - 18001238508<br />

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">How can you place an order with Pure Eats?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         You can place an order with Pure Eats, in any one of the following modes:
                         (i) By PHONE – just call 18001238508..<br />
                         (ii) Online – just login to www.pureeats.in and place your order and have the option of paying
                         online (via a secured Online Payment mode OR by using the Pure Eats Prepaid Account mode)<br />
                         (iii) Use our mobile application app – pure eats<br />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What are the office hours?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We are open Every Day 07:00 AM to 11:59 PM for your food booking needs at any time, day or night, at
                         www.pureeats.in Should you rather talk to one of our helpful customer service representatives, they are
                         available from 7:00 AM to 11:59 PM seven days a week.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Forgot your Username and password?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         DO NOT STRESS – your Username is your Registered Mobile Number.<br />
                         In case of Forgotten Password – we will set this right, almost immediately:<br />
                         (i) Click LOGIN on the top right of the site page to access the login screen.<br />
                         (ii) Click Forgot Password? Link available on the bottom of the login screen.<br />
                         (iii) Enter your mobile number in the displayed field.<br />
                         (iv) We will send you a new password to your registered e-mail ID.<br />
                         (v) IF you DO NOT have an email ID registered with us – just call us (18001238508.), and we will
                         register your email ID, immediately.<br />
                         (vi) You can use the Password, provided in the email, to login.<br />
                         (vii) Remember to reset your password, instantly.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Are the prices listed on this website accurate and binding?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We make every effort to keep the prices updated and accurate. Prices listed on this website are however
                         approximate and subject to changes by the Restaurant and Taxes.<br />
                         The final price is as per the ACTUAL BILL raised by the Restaurant and presented to you, at the time of the
                         delivery.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What if my amount is deducted and I didn’t get confirmation message?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         In such case, call our executives and check whether the order is placed or not. Due to mobile network issues,
                         there can be delay in receiving the order confirmation message. DO NOT WORRY! There’s 100% probability
                         that the order is placed from Pure Eats end and will be delivered at your place of order.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">How do I use promo codes?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Once you’ve added all the items you wish to order to your bag, press “Checkout.” Underneath the “Tip
                         amount” box (don’t forget about the tip amount box!), enter your promo code and press “Add.” Your bag
                         will now reflect the discount. If you have multiple promos applied to your account, simply select the one
                         you wish to use by clicking on it. In most cases, you cannot combine promotions or other offers.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1"> How much is the delivery fees?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Our minimum delivery fee is Rs.20, but it varies by restaurant. We have a tier fee system in place, so the
                         closer you are to the restaurant, the cheaper it will be.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What’s the minimum order size?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The minimum order size is Rs 99 (excluding taxes and our delivery fee).
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Do I need to pay taxes on my order?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Yes. All prices mentioned on the website are exclusive of taxes.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Do I need to tip the delivery boy?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         No, you do not need to tip the delivery boy. The meal price of Pure Eats charges is inclusive of delivery cost.
                         In fact, we would suggest that you do not pay any extra amount that your pre-agreed price.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Where is my personal information used?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We do not share your personal details (phone number and mail ID) with the delivery restaurants. However,
                         we may use your number/mail id to send you promotional offers about Pure Eats. We take security seriously
                         and we take precautions to keep your personal information secure. We have put in place appropriate
                         physical, electronic and managerial procedures to safeguard the information we collect. However, due to
                         the open communication nature of the Internet, Pure Eats cannot guarantee that communications between
                         you and PURE EATS or information stored on pure eats servers, will be free from unauthorized access by
                         third parties.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What if internet connection is lost at any step in between?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         If you received confirmation SMS, your order is booked. If you did not receive the order confirmation SMS,
                         your transaction did not complete and order was not placed. You would have to place the order again online
                         or call our helpful customer service representatives at 18001238508 to help you complete your order.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">I don’t have access to the Internet. Can I still use your services?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Of course! Our helpful customer service representatives are ready to take your order from 7:00 AM to 11:59
                         PM at 18001238508..
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">I am stuck and can’t figure out how to order…?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         No issues. Give us a call at 18001238508 between 7:00 AM and 11:59 PM and our helpful customer service
                         representatives will assist you to complete the order.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">How does Pure Eats work?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We deliver for our customers from anywhere our customers would like. We take your order by internet and
                         place it with the restaurant. We dispatch a meal courier to pick up the food, pack it in an insulated carrier
                         and deliver it directly to you. Usually, your meal courier will be waiting at the restaurant when the food
                         comes out of the kitchen. We are not affiliated with some of the restaurants and in no way are we claiming
                         to be in business with some of the businesses listed, unless otherwise stated.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">How long does it take for a delivery?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Our normal delivery time is between 50 minutes however certain situations such as traffic, weather, and
                         restaurant preparation time require extra time. Please know that we are always working hard to get your
                         food delivered as quickly as possible. We appreciate your patience. Placing orders in advance is appreciated.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Why are there more restaurants in the delivery catalog than on the website?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         We deliver for restaurants all over the city. When you log in to the website, the restaurants within your
                         general delivery range will appear.
                         Payments: -
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What mode of payments are acceptable using Pure Eats?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         You can use any ONE of the following Payment Modes, using Pure Eats…<br />
                         (i) Cash on Delivery.<br />
                         (ii) Online Payment – using Credit Card / Debit Card / Net Banking.<br />
                         (iii) Pure Eats Prepaid Account.<br />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">When I have to pay?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         You will have to pay at the time of delivery as per the payment details we send to you via email.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What payment modes are accepted?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         You can pay by cash or cheque at the time of delivery. We will soon be introducing more payment methods.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Shall I buy the goods on credit?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Sorry, we don’t allow a credit on goods currently.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Can I pay online?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         You can do payU, airtel money, phone pay, Google pay, Debit card, credit card, Net Banking. We will be
                         sharing the details via email.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">Will I get an invoice?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         Yes. You will get your invoice from our supplier at the time of delivery.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{boxShadow: 'none'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="body1">What is your Refund Policy?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         The customer is financially responsible for payment once an order is submitted. If you want to change your
                         order, we will attempt to accommodate such wishes within the time constraints and the good will of the
                         participating restaurants. However, if a change is too late to process, you are responsible for payment of the
                         original order. Changes to orders must be phoned to the staff. Our e-mail is not checked often enough to
                         ensure that we get your changes.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{backgroundColor: '#dedede'}}>
          <div className="col-12">
          <br />
          </div>
        </div>

    </div>
  );
}

