import { GoogleApiWrapper, Map } from "google-maps-react";
import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import mapStyle from "../map-styles.json";
import {GET_ADDRESS_FROM_COORDINATES} from '../../../configs';
import Axios from 'axios';
import Flip from "react-reveal/Flip";
import PlacesAutocomplete from 'react-places-autocomplete';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class GoogleMap extends Component {

    constructor(props){
        super(props);
        this.state={
            initialPosition: {
                lat: 22.5726,
                lng: 88.3639
            },
            zoom: 15,
            ready: false,
            coords: '',
            address: '',
            gpsLoading: false,
        };

        this.setCurrentLocation = this.setCurrentLocation.bind(this);
    }

    componentDidMount() {
        if(this.props.edit){
            this.setState({ ready: true, initialPosition: { lat: this.props.editAddr.latitude, lng: this.props.editAddr.longitude } });
            return;
        }

        if(this.props.place){
            this.setState({ ready: true, initialPosition: { lat: this.props.placeLatLng.lat, lng: this.props.placeLatLng.lng } });
            return;
        }

        // if (localStorage.getItem("userLat") !== null) {
        //     this.setState({ ready: true, initialPosition: { lat: localStorage.getItem("userLat"), lng: localStorage.getItem("userLng") } });
        //     return;
        // }

        this.setCurrentLocation(this)
    }

    setCurrentLocation(that){
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                localStorage.setItem("userLat", position.coords.latitude);
                localStorage.setItem("userLng", position.coords.longitude);
                
                that.props.reverseLookup(position.coords.latitude, position.coords.longitude);
                that.setState({ ready: true, initialPosition: { lat: position.coords.latitude, lng: position.coords.longitude } });

                // var latlng = new that.props.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                // map.setCenter(latlng);
                // clearInterval(animationInterval);
                // secondChild.style['background-position'] = '-144px 0';
            });
        } else {
            console.log('err')
            that.setState({ ready: true,});
            // clearInterval(animationInterval);
            // secondChild.style['background-position'] = '0 0';
        }
    }

    showButton(map, that){
        console.log('showButton');
        var controlDiv = document.createElement('div');

            var firstChild = document.createElement('button');
            firstChild.style.backgroundColor = '#fff';
            firstChild.style.border = 'none';
            firstChild.style.outline = 'none';
            firstChild.style.width = '28px';
            firstChild.style.height = '28px';
            firstChild.style.borderRadius = '2px';
            firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
            firstChild.style.cursor = 'pointer';
            firstChild.style.marginRight = '10px';
            firstChild.style.padding = '0px';
            // firstChild.style.marginBottom = '9px';
            firstChild.title = 'Your Location';
            controlDiv.appendChild(firstChild);

            var secondChild = document.createElement('div');
            secondChild.style.margin = '5px';
            secondChild.style.width = '18px';
            secondChild.style.height = '18px';
            secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
            secondChild.style.backgroundSize = '180px 18px';
            secondChild.style.backgroundPosition = '0px 0px';
            secondChild.style.backgroundRepeat = 'no-repeat';
            secondChild.id = 'you_location_img';
            firstChild.appendChild(secondChild);

            firstChild.addEventListener('click', function () {
                var imgX = 0,
                    animationInterval = setInterval(function () {
                        imgX = -imgX - 18 ;
                        secondChild.style['background-position'] = imgX+'px 0';
                    }, 500);

                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {

                        localStorage.setItem("userLat", position.coords.latitude);
                        localStorage.setItem("userLng", position.coords.longitude);
                        
                        that.reverseLookup(position.coords.latitude, position.coords.longitude);
                        that.setState({ initialPosition: { lat: position.coords.latitude, lng: position.coords.longitude } });

                        var latlng = new that.props.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        map.setCenter(latlng);
                        clearInterval(animationInterval);
                        secondChild.style['background-position'] = '-144px 0';
                    });
                } else {
                    clearInterval(animationInterval);
                    secondChild.style['background-position'] = '0 0';
                }
            });

            controlDiv.index = 1;
            map.controls[this.props.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

    }

    reverseLookup = (lat, lng) => {
        // alert('Please Enable Your device Location to continue.');

        Axios.post(GET_ADDRESS_FROM_COORDINATES, {
            lat: lat,
            lng: lng
        })
            .then(response => {
                console.log('response');
                console.log(response);
                this.props.address(response?.data);
                this.setState({ location: response?.data, dragging: false });
            })
            .catch(function(error) {
                // alert(error.response?.data);
                this.setState({locationModal: true});
                // alert('Please Enable Your device Location to continue.');
                console.warn(error?.response?.data);
            });
    };

    onMarkerDragEnd = map => {
        // console.log(map);
        // console.log({ lat: map.center.lat(), lng: map.center.lng() });
        localStorage.setItem("userLat", map.center.lat());
        localStorage.setItem("userLng", map.center.lng());
        this.reverseLookup(map.center.lat(), map.center.lng());
    };

    handleDragging = () => {
        this.setState({ dragging: true });
    };

    handleChange = address => {
      this.setState({address});
      // setCoords(address)
    };
    
    handleSelect = address => {
      this.setState({address, gpsLoading: true});
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          console.log('Success', latLng)
          localStorage.setItem("userLat", latLng.lat);
          localStorage.setItem("userLng", latLng.lng);
          localStorage.setItem("address", address);
          // setInitialCoords({lat: latLng.lat, lng: latLng.lng});
          this.setState({gpsLoading:false, initialPosition: {lat: latLng.lat, lng: latLng.lng}});
          // history.goBack();
          // history.push('/restaurants');
        })
        .catch(error => console.error('Error', error));
    };


    render() {
        if(!this.state.ready){
            return(<span>...</span>)
        }
        return (
            <React.Fragment>
                <PlacesAutocomplete
                    onChange={(val)=>this.handleChange(val)}
                    onSelect={(val)=>this.handleSelect(val)}
                    value={this.state.address}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input 
                          className="form-control" 
                          style={{display: 'inline', width: '100%', border: 'none', padding: 20, height: 41}} 
                          {...getInputProps({
                            placeholder: "Search for area, street name...",
                          })}
                          disabled={this.state.gpsLoading}
                          id="search"
                          size="small"
                          name="search" />

                        <div style={{padding: 1, width:'100%'}} className="autocomplete-dropdown-container">
                          <div className="MuiPaper-root MuiCard-root jss178 MuiPaper-rounded" style={{background: 'white', position: 'absolute', zIndex: 99999, margin: 10}}>
                            {loading && 
                              <div>
                                <img
                                  src="/assets/img/various/spinner.svg"
                                  className="location-loading-spinner"
                                  alt="loading"
                                />
                              </div>
                            }

                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={suggestion.id}
                                >
                                  <Flip top delay={index * 50} key={suggestion.id}>
                                    <div
                                      className="location-suggestion"
                                      style={{padding: 10, borderBottomWidth: 1, borderBottom: 'daashed'}}
                                    >
                                      <RoomOutlined style={{marginRight: 5}} />
                                      <span className="location-main-name">
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  </Flip>
                                </div>
                              );
                            })}
                          </div>

                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                {!this.state.gpsLoading && (
                <Map
                    google={this.props.google}
                    style={{
                        width: "100%",
                        height: "60vh",
                        position: 'inherit',
                    }}
                    initialCenter={{
                        lat: this.state.initialPosition.lat,
                        lng: this.state.initialPosition.lng
                    }}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(map)}
                    zoom={this.state.zoom}
                    styles={mapStyle}
                    zoomControl={false}
                    mapTypeControl={false}
                    scaleControl={true}
                    streetViewControl={false}
                    showsMyLocationButton={true}
                    fullscreenControl={false}
                    onReady={(mapProps, map) => {
                        this.reverseLookup(this.state.initialPosition.lat, this.state.initialPosition.lng);
                        localStorage.setItem("userLat", map.center.lat());
                        localStorage.setItem("userLng", map.center.lng());
                        this.showButton(map, this);
                    }}
                    onDragstart={() => {
                        this.handleDragging(true);
                    }}
                >
                    {/* <Marker position={this.state.initialPosition}></Marker> */}
                </Map>
                )}

                <img src="/assets/img/android/pin_marker.png" alt="Marker" className="center-marker" style={{width: 48}} />
            </React.Fragment>
        );
    }
}

const MapLoadingContainer = () => (
    <ContentLoader height={400} width={window.innerWidth} speed={1.2} >
        <rect x="0" y="0" rx="0" ry="0" width={window.innerWidth} height="400" />
    </ContentLoader>
);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCt_14My2CYghVw6eZFSYFlFPBOK29lkww',
    LoadingContainer: MapLoadingContainer
})(GoogleMap);
