import React, {useEffect, useState} from "react";

import Typography from '@material-ui/core/Typography';
import {GET_ALERTS_URL,MARK_ONE_NOTIFICATION_READ_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';

export default function Notifications({navigation}){

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});
  // const [orders, setOrders] = useState([]);

  useEffect(()=>{
      let user = JSON.parse(localStorage.getItem('user'))
      // setAccount(user)

      Axios.post(GET_ALERTS_URL, {token:user.auth_token, user_id: user.id})
      .then(res=>{
        console.log(res.data);
        setNotifications(res.data.filter(i=>i.is_read===0))
        setLoading(false);
      })
      .catch(e=>console.log(e));
  }, []);

  const deleteNotification=(id)=>{
    let user = JSON.parse(localStorage.getItem('user'));

    Axios.post(MARK_ONE_NOTIFICATION_READ_URL, {token:user.auth_token, user_id: user.id, notification_id: id})
    .then(res=>{
      setNotifications(res.data.filter(i=>i.is_read===0))
    })
    .catch(e=>console.log(e));
  }

  const handleNotificationClick = (item) => {
    if(item?.data){
      if(item.data){
        let unique_order_id = JSON.parse(item?.data).unique_order_id
        let order_id = parseInt(unique_order_id.replace("PUR", ""))
        if(order_id){
          history.push(`/orderDetails/${order_id}`)
        }
      }
    }
  }

  return (
    <div className="container-fluid bg-app">

        <div className="row bg-white mb-3">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
            <Typography className="ml-2" color="text-secondary" variant="h6" component="span">
            <b>Alerts</b>
            </Typography>
          </div>
        </div>

        {loading && (
          <div className="row">
              {[...Array(10)].map((i)=>{
                  return <div className="col-md-12">
                      <div className="mb-3 bg-white p-3">
                        <Skeleton variant="rect" height={54} />
                        <Skeleton variant="text" style={{marginTop: 5}} />
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={50} />
                      </div>
                  </div>
              })}
          </div>
          )}


          <div className="row bg-white">
            <div className="col-12 p-0">
              <div className="p-0">
                <List component="nav" className="pt-0" aria-label="main mailbox folders">
                  {!loading && notifications.length>0 && (
                      notifications.map((item)=>{
                          return <>
                          <ListItem onClick={() => handleNotificationClick(item)} button key={item.id}>
                            <ListItemIcon>
                              <NotificationsActiveOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText 
                              primary={JSON.parse(item.data).title}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {JSON.parse(item.data).message}
                                  </Typography>
                                  <br />
                                  {new Date(item.created_at).toLocaleString()}
                                </React.Fragment>} />
                              
                                <ListItemSecondaryAction>
                                  <IconButton onClick={()=>deleteNotification(item.id)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>

                          </ListItem>
                          <Divider />
                          </>
                      })
                  )}
                </List>
              </div>
            </div>
          </div>

        {notifications.length===0 && (
        <div className="row bg-white">
          <div className="col-12">
            <div className="p-3">
              <img alt="No Data" src="/assets/img/android/error_no_data.png" className="img-fluid" />
            </div>
          </div>
        </div>
        )}

    </div>
  );
}

