import React, {useState, useEffect} from "react";

import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Axios from 'axios';
import {ROOT_URL} from '../../../configs';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import CircularProgress from '@material-ui/';
import {GET_RESTAURANT_CURRENT_STATUS_URL} from '../../../configs';

export default function Cart(params){

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [restaurant, setRestaurant] = useState({})
  const [cart, setCart] = useState([]);
  const [tip, setTip] = useState(0);
  const [coupon, setCoupon] = useState(null);
  // const [showBackdrop, setShowBackdrop] = useState(false);
  const [operationalData, setOperationalData] = useState({});
  const [orderComment, setOrderComment] = useState('');

  const wait = (v) => {
    // if (v) {
    //   setShowBackdrop(v)
    // }

    // if(!v){
    //   setTimeout(() => {setShowBackdrop(v)}, 700);
    // }
  }
  
  useEffect(()=>{
    let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
    let cartItems = JSON.parse(localStorage.getItem('cart'));
    // console.log(cartRestaurant);
    setRestaurant(cartRestaurant);
    setCart(cartItems);

    let cp = localStorage.getItem('applyedCoupon');
    let oc = localStorage.getItem('orderComment');
    let t = localStorage.getItem('tip');
    
    if (cp) {
      setCoupon(JSON.parse(cp));
    }
    if (oc) {
      setOrderComment(oc);
    }
    if (t) {
      setTip(parseInt(t));
    }
  }, [])

  const fetchCurrentRestaurantStatus = () => {
    let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
    
    const raw = {
      restaurant_id : cartRestaurant.id,
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
      delivery_type: localStorage.getItem('delivery_type') === 'delivery' ? 1 : 0
    }

    Axios.post(GET_RESTAURANT_CURRENT_STATUS_URL, raw)
    .then(res=>{
      setOperationalData(res.data)
      
      cartRestaurant.delivery_charge= res.data.restaurant.delivery_charge
      cartRestaurant.delivery_distance= res.data.restaurant.delivery_distance

      localStorage.setItem('cartRestaurant', JSON.stringify(cartRestaurant));
    })
    .catch(e => {
      console.log(e)
    })
      
    // curl --location --request POST 'http://pureeats.live/public/api/get-restaurant-info-and-operational-status' \
    // --header 'Content-Type: application/json' \
    // --data-raw '{
    //     "restaurant_id" : 1,
    //     "latitude":22.685935974121094,
    //     "longitude":88.34288024902344
    // }'
  }

  useEffect(() => {
    if(localStorage.getItem('cartRestaurant')){
      fetchCurrentRestaurantStatus()
      // calculateFee();
      // let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
      // let baseCharge = parseInt(cartRestaurant.delivery_charge)
      
      // setDeliveryCharge(Math.round(baseCharge))
    }
  },[])

  const addToCart=(item)=>{
    wait(true)
    setCart([...cart, {...item, quantity: 1, cartPrice: item.price}])
    wait(false)
  }

  const incrementCart=(citem)=>{
    wait(true)
    let items = [...cart];
    let itemIndex = cart.findIndex((i)=>i.id === citem.id);
    let item = items[itemIndex]
    
    item.quantity=item.quantity+1;
    item.cartPrice=parseInt(item.price)*(item.quantity)
    
    items[itemIndex] = item;
    setCart(items)
    localStorage.setItem('cart', JSON.stringify(items));
    wait(false)
  }

  const decrementCart=(citem)=>{
    wait(true)
    // console.log(citem)

    let item = cart.filter((i)=>i.id === citem.id)[0]
    
    if (item.quantity === 1) {
      let newItems = cart.filter((i)=>i.id !== citem.id)
      
      item.cartPrice=item.cartPrice - parseInt(item.price)
      
      setCart(newItems)

      localStorage.setItem('cart', JSON.stringify(newItems));
      
      if(newItems.length===0){
        localStorage.removeItem('cart');
      }

      if(newItems.length===0){
        localStorage.removeItem('orderComment');
        localStorage.removeItem('tip');
      }
    }else{
      const itemIndex = cart.findIndex((i)=>i.id === citem.id)
      
      let newItems = [...cart] //.filter((i)=>i.id !== citem.id)
      
      item.cartPrice=parseInt(item.price)*(item.quantity-1)
      item.quantity = item.quantity-1
      
      newItems[itemIndex] = item
      
      setCart(newItems)

      localStorage.setItem('cart', JSON.stringify(newItems));
    }

    if(coupon!==null){
      if(parseInt(item.cartPrice) < parseInt(coupon.min_order_amount)){
        localStorage.removeItem('applyedCoupon');
        setCoupon(null);
      }
    }

    wait(false)

  }

  const renderCoupon=()=>{
    if(coupon===null){
      return(
        <div className="row" style={{borderBottom: '1px solid'}}>
            <div className="col-9 p-3" onClick={()=>(localStorage.getItem('user')===null)?history.push('/login'):history.push('/coupons')}>
              <Typography variant="body1"><b>APPLY COUPON</b></Typography>
            </div>
            <div className="col-3">
              <IconButton className="float-right" onClick={()=>(localStorage.getItem('user')===null)?history.push('/login'):history.push('/coupons')} aria-label="back">
                <ChevronRightOutlinedIcon style={{marginRight: 5}} />
              </IconButton>
            </div>
        </div>
      )
    }else{
      return(
        <div className="row" style={{borderBottom: '1px solid', backgroundColor: '#00a000 '}}>
            <div className="col-9 p-3" onClick={()=>history.push('/coupons')}>
              <Typography style={{color: 'white'}} className="text-center" variant="body1"><b>{coupon.code} Coupon Applied</b></Typography>
            </div>
            <div className="col-3">
              <IconButton className="float-right" onClick={()=>removeCoupon()} aria-label="back">
                <CloseIcon style={{marginRight: 5, color: 'white'}} />
              </IconButton>
            </div>
        </div>
      )
    }
  }

  const removeCoupon=()=>{
    wait(true)
    setCoupon(null)
    localStorage.removeItem('applyedCoupon');
    
    enqueueSnackbar('Coupon removed! ', {variant: 'error'});
    wait(false)
  }

  const calculateDiscount=()=>{
    if(coupon === null) return 0;

    if(coupon.discount_type==="PERCENTAGE"){
      let t = subTotal();
      let dis = Math.round((t*parseInt(coupon.discount))/100);
      if (dis > parseInt(coupon.max_discount)) {
        return parseInt(coupon.max_discount);
      }
      return dis;
    }
    if(coupon.discount_type==="AMOUNT"){
      // let t = subTotal();
      let dis = parseInt(coupon.discount);
      if (dis > parseInt(coupon.max_discount)) {
        return parseInt(coupon.max_discount);
      }
      return dis;
    }
  }

  function subTotal(){
    let total = 0;
    cart.map((i)=>total+=parseInt(i.cartPrice))
    // console.log(total)
    localStorage.setItem('subTotal', total)
    return parseInt(total);
  }

  const totalPay=()=>{
    let subTotal1 = subTotal();
    
    let tax = parseInt(localStorage.getItem('taxPercentage')?localStorage.getItem('taxPercentage'):5)/100
    let restaurant_charges = Math.round(subTotal()*(parseInt(restaurant.restaurant_charges)/100) * 100) / 100
    
    const totalDeliveryCharge = localStorage.getItem('delivery_type') === 'delivery' ? parseInt(operationalData?.restaurant?.delivery_charge) : 0
    const netAmount = restaurant_charges + (subTotal1+(Math.round(tax))+parseInt(totalDeliveryCharge)+taxPay()+tip)-calculateDiscount();
    return Math.round(netAmount);
    // return (subTotal1+(Math.round(subTotal1*0.02))+parseInt(deliveryCharge)+taxPay()+tip)-calculateDiscount();
  }

  const taxPay=()=>{
    let subTotal1 = subTotal();
    let tax = parseInt(localStorage.getItem('taxPercentage')?localStorage.getItem('taxPercentage'):5)/100
    return  Math.round(subTotal1 * tax * 100) / 100
  }

  const proceedCheckout=()=>{
    if(localStorage.getItem('user')===null){
      localStorage.setItem('fromCart', true);
      history.push('/login');
      return;
    }
    localStorage.setItem('totalPayable', totalPay());
    localStorage.setItem('tip', tip);
    localStorage.setItem('orderComment', orderComment);

    history.push('/checkout');
  }

  if(cart === null || cart.length === 0){
    return(
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="m-3">
              <img alt='imagehere' src="/assets/img/empty.png" className="img-fluid" />
            </div>
            
            <Typography variant="h6" className="text-muted text-center"><b>Cart Is Empty</b></Typography>

            <Button onClick={()=>history.push('/restaurants')} style={{
              left: '30%',
              background: 'white',
              color: 'green',
              borderRadius: 0,
              marginTop: 28,
            }} variant="contained">Find Food</Button>

          </div>
        </div>
      </div>
    )
  }

  const handleOrderComment = (v) => {
    setOrderComment(v)
    localStorage.setItem('orderComment', v)
  }

  const handleTip = (v) => {
    setTip(parseInt(v))
    localStorage.setItem('tip', parseInt(v));
  }


  return (
    <>
    <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <IconButton onClick={()=>history.goBack()} aria-label="back">
              <KeyboardBackspaceIcon style={{marginRight: 5}} />
            </IconButton>
          </div>
        </div>

        <div className="row m-1" style={{borderBottom: '1px solid #ababab'}}>
            <div className="col-sm-12">
              <div className="row mb-3">
                <div className="col-12 col-md-8 p-0">
                  <div className="p-2">
                    <Typography variant="h6">
                      <b>CART ({restaurant.name})</b>
                    </Typography>
                    <Typography className="text-danger" variant="body2">
                      {restaurant.address}
                    </Typography>

                    {/* {deliveryTime > 0 && (
                      <Typography style={{fontWeight: 'bold', fontSize: 18, marginTop: 10}} className="text-success" variant="body2">
                        {localStorage.getItem('delivery_type') === 'self' ? 'Pickup' : 'Delivery'} in {Math.round((deliveryTime / 60))} mins
                      </Typography>
                    )} */}

                    {localStorage.getItem('delivery_type') === 'self' && (
                      <Typography style={{fontWeight: 'bold', fontSize: 14, marginTop: 10}} className="text-success" variant="body2">
                        Self puckup order
                      </Typography>
                    )}

                    
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="row dashed-bottom">
            {cart.map((i)=>{
              return (<div key={i.id} className="col-12">
                  <div className="p-3">
                      <div className="row">
                        <div className="col-3 p-0">
                          {i.is_veg===1 && (
                            <img alt="..." src="/assets/img/various/veg-icon.png" style={Styles.vegIcon} />
                          )}
                          {i.is_veg!==1 && (
                            <img alt="..." src="/assets/img/various/non-veg-icon.png" style={Styles.vegIcon} />
                          )}
                          <div style={{width: 75, maxHeight: 180, minWidth: 54, minHeight: '54px', backgroundColor: '#dcdcdc'}}>
                            {i.image!==null && (
                              <img className="img-fluid" src={ROOT_URL+i.image} alt="PureEats" />
                            )}
                            {i.image===null && (
                              <img className="img-fluid page-inactive" src='/assets/img/logos/default.png' alt="PureEats" />
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <CardActionArea>
                          <CardContent className="p-1">
                            <Typography gutterBottom variant="body1" component="h2">
                              <b>{i.name}</b>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              ₹ {i.price}
                            </Typography>
                          </CardContent>
                          </CardActionArea>
                        </div>
                        <div className="col-3">
                          {cart.length === 0 && (
                            <Button onClick={()=>addToCart(i)} style={{
                              width: 70,
                              marginLeft: 'auto',
                              position: 'absolute',
                              bottom: 5,
                              left: '24%',
                              background: 'white',
                              color: 'green',
                              borderRadius: 0,
                            }} variant="contained">ADD</Button>
                          )}
                          {cart.filter(a => a.id !== i.id).length !== 0 && (
                            <Button onClick={()=>addToCart(i)} style={{
                              width: 70,
                              marginLeft: 'auto',
                              position: 'absolute',
                              bottom: 5,
                              left: '24%',
                              background: 'white',
                              color: 'green',
                              borderRadius: 0,
                            }} variant="contained">ADD</Button>
                          )}

                          {cart.some(a => a.id === i.id) && (
                          <div className="MuiButton-contained" style={{
                            width: 70,
                            marginLeft: 'auto',
                            position: 'absolute',
                            bottom: 5,
                            left: '24%',
                            background: 'white',
                            color: 'green',
                            borderRadius: 0,
                            padding: 3,
                          }}>
                            <IconButton onClick={()=>incrementCart(i)} style={{float: 'right'}} size="small" aria-label="plus">
                              <AddIcon fontSize="small" />
                            </IconButton>
                            <p style={{paddingLeft: 1,marginTop: 3,display: 'inline-block',marginBottom: 0}}>{cart.filter(a=>a.id===i.id)[0].quantity}</p>
                            <IconButton onClick={()=>decrementCart(i)} style={{float: 'left'}} size="small" aria-label="delete">
                              <RemoveIcon fontSize="small" />
                            </IconButton>
                          </div>
                          )}
                        </div>
                      </div>
                  </div>
              </div>)
          })}
        </div>

        {/* TODO: ADD NOTE  order_comment*/}

        <input 
          type="text" 
          name="order_comment" 
          onChange={(e) => handleOrderComment(e.target.value)}
          value={orderComment}
          placeholder="Special cooking instructions." 
          style={{width: '100%', margin: '9px 0px', border: '0px'}} 
        />

        {localStorage.getItem('delivery_type') === 'delivery' && (
        <div className="row" style={{borderBottom: '1px solid', backgroundColor: '#e5e6e6'}}>
            <div className="col-sm-12">
              <div className="row p-3">
                <div className="col-10 col-md-8 p-0 ">
                  <div className="p-2">
                    <Typography variant="body1" className="text-muted">
                      <b>SUPPORT YOUR RIDER</b>
                    </Typography>
                    <Typography className="text-muted" variant="caption">
                      Our riders are risking their lives to serve the nation.
                      While we're doing our best to support them, we'd
                      request you to tip them generously in this difficult
                      time, if you can aford it
                    </Typography><br />
                  </div>
                </div>
                
                <div className="col-2 col-md-8 p-0 text-center">
                  {tip>0 && (
                  <><Typography className="mt-4 pt-3 text-muted" variant="h6">
                    <b>₹ {tip}</b>
                  </Typography>
                  <Typography onClick={()=>handleTip(0)} className="text-danger text-center" variant="body2">
                    Clear
                  </Typography></>
                  )}
                </div>

                <div className="row col-12 mt-4">
                  <div className="col-4">
                    <Button onClick={()=>handleTip(tip+5)} className="bg-white" style={{borderRadius: 8}}>
                    <Typography variant="body1"><b>+ ₹ 5</b></Typography>
                    </Button>
                  </div>
                  <div className="col-4">
                    <Button onClick={()=>handleTip(tip+10)} className="bg-white" style={{borderRadius: 8}}>
                    <Typography variant="body1"><b>+ ₹ 10</b></Typography>
                    </Button>
                  </div>
                  <div className="col-4">
                    <Button onClick={()=>handleTip(tip+20)} className="bg-white" style={{borderRadius: 8}}>
                    <Typography variant="body1"><b>+ ₹ 20</b></Typography>
                    </Button>
                  </div>
                </div>

              </div>
            </div>
        </div>
        )}

        {renderCoupon()}

        <div className="row m-1">
            <div className="col-12 p-3 mt-2 mb-2">
              <Typography className="text-danger" variant="body2">
                Orders once placed cannot be cancelled after 2 minutes of 
                booking and are non-refundable
              </Typography>
            </div>
        </div>

        <div className="row m-1">
            <div className="col-12">
              <Typography variant="body1"><b>BILL DETAILD</b></Typography>
              <div className="row">
                <div className="col-8">
                  <Typography className="p-2" variant="body2">Item Total</Typography>
                </div>
                <div className="col-4">
                  <Typography className="p-2 float-right" variant="body2">₹ {String(subTotal()).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}</Typography>
                </div>

                <div className="col-8">
                  <Typography className="p-2 text-success" variant="body2">Total Discount</Typography>
                </div>
                <div className="col-4">
                  <Typography className="p-2 text-success float-right" variant="body2">- ₹ {calculateDiscount()}</Typography>
                </div>

                {coupon!==null && (
                  <div className="col-12">
                    <div className="row" style={{border:'1px solid #00ab05', padding: 5, textAlign:'center', backgroundColor: '#e4ffe8'}}>
                      <div className="col-8">
                        <Typography style={{color: '#00ab05'}} className="p-2" variant="h5"><b>Your total saving</b></Typography>
                      </div>
                      <div className="col-4">
                        <Typography style={{color: '#00ab05'}} className="p-2 float-right" variant="h5"><b> ₹ {calculateDiscount()}</b></Typography>
                      </div>
                    </div>
                  </div>
                )}

                {localStorage.getItem('delivery_type') === 'self' && (
                  <div className="col-12">
                    <div className="row" style={{border:'1px solid #00ab05', padding: 5, textAlign:'center', backgroundColor: '#e4ffe8'}}>
                      <div className="col-12">
                        <Typography style={{color: '#00ab05'}} className="p-2" variant="h5"><b>Your have choosen Self Pickup</b></Typography>
                      </div>
                    </div>
                  </div>
                )}

                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <>
                      <div className="col-8">
                        <Typography style={{display: 'flex'}} className="text-danger" variant="body2">Taxes & Charges <ArrowDropDownIcon style={{paddingBottom: 5}} /></Typography> 
                      </div>
                      <div className="col-4">
                        <Typography className="text-danger float-right" variant="body2">₹ {(Math.round((subTotal() * parseInt(restaurant.restaurant_charges) / 100) * 100) / 100 + taxPay() + parseFloat(localStorage.getItem('delivery_type') === 'delivery' ? operationalData?.restaurant?.delivery_charge : 0)).toFixed(2)}</Typography>
                      </div>
                    </>
                  </AccordionSummary>

                  <AccordionDetails>
                  <div className="row">

                    <div className="col-9">
                      <Typography className="p-2" variant="body2">Restaurant Packaging Charges</Typography>
                    </div>
                    <div className="col-3">
                      <Typography className="p-2 float-right" variant="body2">₹ {Math.round(subTotal()*(parseInt(restaurant.restaurant_charges)/100) * 100) / 100}</Typography>
                    </div>

                    <div className="col-8">
                      <Typography className="p-2 text-danger" variant="body2">Taxes </Typography>
                    </div>
                    <div className="col-4">
                      <Typography className="p-2 text-danger float-right" variant="body2">₹ {taxPay()}</Typography>
                    </div>

                    {localStorage.getItem('delivery_type') === 'delivery' && (
                      <>
                      <div className="col-8">
                        <Typography className="p-2" variant="body2">Delivery Charge for {operationalData?.restaurant?.delivery_distance || 'N/A'} Km</Typography>
                      </div>

                      <div className="col-4">
                        <Typography className="p-2 float-right" variant="body2">₹ {String(operationalData?.restaurant?.delivery_charge)}</Typography>
                      </div>
                    </>
                    )}

                  </div>
                  </AccordionDetails>

                </Accordion>

                {tip > 0 && (
                <><div className="col-8" style={Styles.bbs}>
                  <Typography className="p-2 text-primary" variant="body2">Tip</Typography>
                </div>
                <div className="col-4" style={Styles.bbs}>
                  <Typography className="p-2 text-primary float-right" variant="body2">₹ {tip}</Typography>
                </div></>
                )}

                <div className="col-8 mb-4">
                  <Typography className="p-2" variant="h6"><b>To Pay</b></Typography>
                </div>
                <div className="col-4">
                  <Typography className="p-2 float-right" variant="h6"><b>₹ {totalPay()}</b></Typography>
                </div>

              </div>
            </div>
        </div>

        {localStorage.getItem('delivery_type') === 'self' && (
          <div className="row" style={{borderTop: '1px solid', marginBottom: 70, color: 'black', textAlign: 'center'}}>
            <div className="col-12 p-3">
              <Typography variant="body1">Pickup your order at the restaurant!</Typography>
            </div>
          </div>
        )}

        {localStorage.getItem('delivery_type') === 'delivery' && (
        <>
        <div className="row" style={{borderTop: '1px solid'}}>
            <div className="col-12 p-3">
              <Typography variant="body1"><b>DELIVERY ADDRESS</b></Typography>
            </div>
        </div>

        <div className="row" style={{marginBottom: 75}}>
            <div className="col-2 text-center">
              <img alt='imagehere' src="/assets/img/android/pin_marker.png" className="img-fluid" style={{width: 54}} />
            </div>
            <div className="col-10">
              <Typography variant="body1"><b>Work</b></Typography>
              <Typography variant="caption">{localStorage.getItem("address")}</Typography>
            </div>

            <div className="col-12">
              <Button onClick={()=>(localStorage.getItem('user')===null)?history.push('/login'):history.push('/manageAddress')} style={{marginTop: 50}}>CHANGE ADDRESS</Button>
            </div>
        </div>

        </>
        )}


        <div className="row" style={{position: 'fixed', width: '100%', bottom: 0}}>
          <div className="col-12 p-0">
            {!operationalData.operational_status?.is_operational && (
              <Button onClick={()=>null} variant="contained" style={{width: '100%', borderRadius: 0, backgroundColor: 'rgb(187 187 187)', height: 50, color: 'white'}} disabled={true}>{operationalData.operational_status?.message}</Button>
            )}

            {( operationalData.operational_status?.is_operational) && (
              <Button onClick={()=>proceedCheckout()} variant="contained" style={{width: '100%', borderRadius: 0, backgroundColor: '#00a000', height: 50, color: 'white'}}>Proceed To Pay</Button>
            )}
          </div>
        </div>
    </div>
    

    {/* <Backdrop
      style={{zIndex: 9999}}
      sx={{ color: '#fff', zIndex: 999999999 }}
      open={showBackdrop}
      onClick={() => null}
    >
      <CircularProgress color="inherit" />
    </Backdrop> */}
    </>
  );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
  bts:{
    borderTop: '1px solid #ababab',
  },
  btd:{
    borderTop: '1px dashed',
  },
  bbs:{
    borderBottom: '1px solid #ababab',
  },
  bbd:{
    borderBottom: '1px dashed',
  },
}
