import React, {useState} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory, Redirect } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {LOGIN_USER_MOBILE_URL, SAVE_ADDRESS_URL, LOGIN_USER_MOBILE_OTP_URL} from '../../../configs';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';

export default function SetLocation() {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  
  const login=()=>{
    if(otpSent){
      verifyOtp();
      return;
    }

    if (mobile.length < 10) {
      enqueueSnackbar('Please Enter a valid mobile no.', {variant: 'error'});
      return;
    }

    setLoading(true);

    Axios.post(`${LOGIN_USER_MOBILE_URL}`, {phone: mobile})
    .then(res=>{
      console.log('res.data', res.data)
      if (res.data?.status === "CODE_SEND"){
        enqueueSnackbar('OTP sent to your mobile no.', {variant: 'success'});
        setOtpSent(true)
        setLoading(false);
      }else{
        // enqueueSnackbar('No account found!', {variant: 'error'});
        registerOtp();
      }
    })
    .catch((e) => {
      // enqueueSnackbar('Invalid OTP!', {variant: 'error'});
      console.log('e', e)
    } 
    )
  }

  const registerOtp=()=>{

    setTimeout(()=>{history.push('/signup/'+mobile)},1200)

    // Axios.get(`${NEW_REGISTER_OTP}/${mobile}`)
    // .then(res=>{
    //   console.log(res.data)
    //   if (res.data.success) {
    //     enqueueSnackbar('OTP sent to your mobile no.', {variant: 'success'});
    //     setLoading(false);
    //     setTimeout(()=>{history.push('/signup/'+mobile)},1200)
    //   }else{
    //     enqueueSnackbar('Invalid Mobile No.!', {variant: 'error'});
    //     setLoading(false);
    //   }
    // })
    // .catch((e)=>console.log(e))
  }

  const verifyOtp=()=>{
    if (mobile.length < 10 || otp.length < 4) {
      enqueueSnackbar('Please Enter a OTP.', {variant: 'error'});
      return;
    }

    setLoading(true);

    Axios.post(LOGIN_USER_MOBILE_OTP_URL, {phone: mobile, otp})
    .then(res=>{
      
      console.log(res.data)

      if(!res.data.success){
        enqueueSnackbar(res.data?.message || "Invalid OTP", {variant: 'error'});
        setLoading(false);
        return
      }

      if(!res.data.data){
        setTimeout(()=>{history.push('/signup/'+mobile)},1200)
        return
      }

      if (res.data.data) {
        enqueueSnackbar('Logged In successfully!', {variant: 'success'});
        localStorage.setItem('user', JSON.stringify(res.data.data));

        if(localStorage.getItem('guest_address')!==null){
          let guest_address = JSON.parse(localStorage.getItem('guest_address'));
          
          let raw = {
            token : res.data.data.auth_token,
            user_id : res.data.data.id,
            latitude : guest_address.latitude,
            longitude : guest_address.longitude,
            address: guest_address.address,
            house : guest_address.flat,
            landmark: guest_address.landmark,
            tag : (guest_address.tag===0)?'HOME':(guest_address.tag===1)?'OFFICE':'OTHER',
            get_only_default_address: null
          }
          saveLocation(raw)

          // let user = res.data.data;
          // user.default_address = guest_address;
          // localStorage.setItem('user', JSON.stringify(res.data.data));
        }
        else{
          setTimeout(()=>{
            if(localStorage.getItem('fromCart')!==null){
              history.push('/cart')
            }else{
              history.push('/restaurants')
            }
          }, 800);
          setLoading(false);
        }
      }else{
        enqueueSnackbar(res.data.message, {variant: 'error'});
        setLoading(false);
      }
    })
    .catch((e)=>{
      enqueueSnackbar('Invalid OTP!', {variant: 'error'});
      setLoading(false);
      console.log(e)
    })
  }

  const saveLocation=(raw)=>{
    Axios.post(SAVE_ADDRESS_URL, raw)
    .then(res=>{
      console.log(res.data)
      // localStorage.setItem("address", address);

      //update default address
      let user = JSON.parse(localStorage.getItem("user"));
      user.default_address = res.data[0];
      user.default_address_id = res.data[0].id;
      localStorage.setItem("user", JSON.stringify(user));
      
      setTimeout(()=>{
        if(localStorage.getItem('fromCart')!==null){
          history.push('/cart')
        }else{
          history.push('/restaurants')
        }
      }, 800);
      setLoading(false);

    })
    .catch(e=>console.log(e));

  }

  if(localStorage.getItem('user') !== null ){
    return(<Redirect to="/account" />)
  }

  return (
        <div className="container">
            <div className="row">

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 30, paddingTop: 10}}>
                    <Typography style={{position: 'absolute', right: 10}} variant="body1" gutterBottom>
                      <Link to="/restaurants">Skip</Link>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div style={{paddingBottom: 10}}>
                    <Typography variant="caption" gutterBottom>
                      Welcome to
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                      <b>PURE EATS</b>
                    </Typography>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div style={Styles.sliderDiv}>
                    <img alt="slider" style={Styles.sliderImg} src="/assets/img/food.jpg" />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 p-0">

                  <div style={{padding: 25}}>

                    <div style={{paddingBottom: 10}}>
                      <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                        <b>LOGIN/CREATE YOUR ACCOUNT</b>
                      </Typography>
                    </div>

                    <div className="row" style={{marginTop: 25, marginBottom: '1rem',}}>
                      <div className="col-sm-12">
                        <TextField 
                          disabled={otpSent} 
                          maxLength={10}
                          inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                          onChange={(e)=>setMobile(e.target.value)} 
                          style={{marginBottom: 15, width: '100%'}} 
                          label="Mobile No." />
                      </div>
                      
                      {otpSent && (
                      <div className="col-sm-12">
                        <TextField 
                          onChange={(e)=>setOtp(e.target.value)} 
                          maxLength={5}
                          inputProps={{ maxLength: 5, inputMode: 'numeric', pattern: '[0-9]*' }}
                          style={{marginBottom: 15, width: '100%'}} 
                          label="OTP" />
                      </div>
                      )}

                      <div className="col-sm-12">
                        <div style={{textAlign: 'center', width: '100%', marginBottom: 25}}>
                          <Button 
                            disabled={loading}
                            onClick={()=>login()} 
                            style={{marginTop: 50, fontSize: 11, height: 45, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} 
                            variant="contained" 
                            color="primary">
                            {(loading)?"Please Wait...":"CONTINUE"}
                          </Button>
                        </div>
                      </div>
                      {/*<div className="col-sm-12">
                        <div style={{Padding: 20}}>
                          <Typography className="mt-3" style={{fontSize: '0.89rem', textAlign: 'center'}} variant="h6" gutterBottom>
                            Dont have an account ? <Link style={{borderBottom: '2px solid black'}} to='signup'>CREATE NOW</Link>
                          </Typography>
                        </div>
                      </div>*/}
                    </div>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}

const Styles = {
  sliderDiv: {
    marginTop: 40,
    textAlign: 'center',
    justifyContent: 'space-around', 
    overflowY: 'scroll'
  },
  sliderImg: {
    width: 'auto',
    height: 145,
    fontSize: 25,
    background: '#cccccc',
    margin: 5,
    display: 'inline-block',
    lineHeight: 100,
  }
}