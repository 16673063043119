import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import GoogleMap from "../../../components/GeoLocation/Map";
import TextField from '@material-ui/core/TextField';
import {GET_ADDRESS_FROM_COORDINATES, SAVE_ADDRESS_URL, POST_USER_ADDRESS} from '../../../configs';
import Axios from 'axios';
// import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';

export default function SetLocation() {
  const history = useHistory();

  const [address, setAddress] = useState('');
  const [flat, setFlat] = useState('');
  const [landmark, setLandmark] = useState('');
  const [edit, setEdit] = useState(false);
  const [editAddr, setAddr] = useState({});
  const [gpsLoading, setGpsLoading] = useState(false);
  // const [latLng, setLatLng] = useState({});
  const [tag, setTag] = useState(0);
  const [place, setPlace] = useState(false);
  const [placeLatLng, setPlaceLatLng] = useState({});

  
  useEffect(()=>{
    if(history.location.state !== undefined){
      if(history.location.state.edit === true && history.location.state.manageAddr !== undefined){
        setAddress(history.location.state.manageAddr.address);
        setEdit(true)
        setAddr(history.location.state.manageAddr)
        setTag((history.location.state.manageAddr.tag==='HOUSE')?0:(history.location.state.manageAddr.tag==='OFFICE')?1:2)
        setFlat(history.location.state.manageAddr.house)
        setLandmark(history.location.state.manageAddr.landmark)
      }

      if(history.location.state.place === true && history.location.state.lat !== undefined && history.location.state.lng !== undefined){
        setPlace(true);
        setAddress(history.location.state.address);
        setPlaceLatLng({lat:history.location.state.lat, lng:history.location.state.lng});
      }
    }
  },[history.location.state])

  const reverseLookup = (lat, lng) => {
    setGpsLoading(true);
    // setLatLng({lat, lng})
    Axios.post(GET_ADDRESS_FROM_COORDINATES, {
      lat: lat,
      lng: lng
    })
      .then(response => {
        // alert(response.data);
        const myLocation = [
          {
            formatted_address: response?.data,
            geometry: {
              location: {
                lat: lat,
                lng: lng
              }
            }
          }
        ];
        console.log(myLocation)
        setGpsLoading(false);
        setAddress(myLocation[0].formatted_address)
        // this.handleGeoLocationClick(myLocation);
      })
      .catch(function(error) {
        console.warn(error?.response?.data);
      });
  };

  const saveLocation=()=>{
    if (address===null) return;

    let user = localStorage.getItem("user");

    if(user===null){
      localStorage.setItem("address", address);
      localStorage.setItem("guest_address", JSON.stringify({"address": localStorage.getItem('address'), "latitude": localStorage.getItem('userLat'), "longitude": localStorage.getItem('userLng'), "tag":tag, "flat": flat, "landmark": landmark}));
      history.push('/restaurants');
      return;
    }

    user = JSON.parse(user);

    let raw = {
      token : user.auth_token,
      user_id : user.id,
      latitude : localStorage.getItem('userLat'),
      longitude : localStorage.getItem('userLng'),
      address,
      house : flat,
      landmark,
      tag : (tag===0)?'HOME':(tag===1)?'OFFICE':'OTHER',
      get_only_default_address: null
    }

    if(edit){
      raw.address_id = editAddr.id
    }

    Axios.post((!edit)?SAVE_ADDRESS_URL:POST_USER_ADDRESS, raw)
    .then(res=>{
      console.log(res.data)
      localStorage.setItem("address", address);

      //update default address
      user.default_address = res.data[0];
      user.default_address_id = res.data[0].id;
      localStorage.setItem("user", JSON.stringify(user));
      
      history.push('/restaurants');
    })
    .catch(e=>console.log(e));

  }

  const handleTagChange = (event, newValue) => {
    setTag(newValue);
  };


  return (
        <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 p-0">
                <div style={{height: "70vh"}}>
                  {console.log(place)}
                  <GoogleMap place={place} placeLatLng={placeLatLng} edit={edit} editAddr={editAddr} address={(addr)=>setAddress(addr)} reverseLookup={reverseLookup} />
                  }
                </div>
              </div>
            </div>
            <div className="row">

                <div className="col-sm-12 col-md-6 p-0">

                  <div style={{padding: 30}}>

                    <div style={{paddingBottom: 10}}>
                      <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                        <b>Set Delivery Location</b>
                      </Typography>
                    </div>

                    <div className="row" style={{marginBottom: '2.5rem',}}>
                      <div className="col-sm-12">
                        <TextField disabled={true} style={{marginBottom: 15, width: '100%'}} value={(gpsLoading)?'Fetching Address...':address} label="LOCATION" />
                      </div>
                      <div className="col-sm-12">
                        <TextField value={flat} onChange={(e)=>setFlat(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="HOUSE/FLAT NO." />
                      </div>
                      <div className="col-sm-12">
                        <TextField value={landmark} onChange={(e)=>setLandmark(e.target.value)} style={{marginBottom: 15, width: '100%'}} label="LANDMARK" />
                      </div>
                    </div>

                    <div className="row" style={{marginBottom: '2.5rem',}}>
                      <div className="col-sm-12">
                        <div style={{paddingBottom: 10}}>
                          <Typography style={{fontSize: '0.89rem'}} variant="caption" gutterBottom>
                            <b>Save As</b>
                          </Typography>
                        </div>

                        <div className="mb-4">
                          <Tabs
                            value={tag}
                            onChange={handleTagChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="tags"
                          >
                            <Tab icon={<HomeOutlinedIcon />} label="HOME" />
                            <Tab icon={<BusinessCenterOutlinedIcon />} label="OFFICE" />
                            <Tab icon={<MoreHorizOutlinedIcon />} label="OTHER" />
                          </Tabs>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style={{position: 'fixed', bottom: 0, textAlign: 'center', width: '100%'}}>
                    <Button onClick={()=>saveLocation()} style={{marginTop: 10, fontSize: 11, height: 50, width: '100%', backgroundColor: '#bb1229', borderRadius: 0, color: 'white'}} variant="contained" color="primary">
                      SAVE LOCATION
                    </Button>
                  </div>

                </div>
                
            </div>


        </div>
    
  );
}

